import { fetchBrands, updateBrand } from '@/api/product'
import { useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useUsersList() {
  const brandListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'LOGO', value: 'media', sortable: false },
    { text: 'SLUG', value: 'slug' },
    { text: 'NAME', value: 'brand', sortable: false },
    { text: 'STATUS', value: 'status', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const filters = ref({
    search: '',
    distributorId: null,
  })
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const loadBrands = () =>
    fetchBrands(
      useTableOptions(options.value, {
        ...filters.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        brandListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllBrands = async (updates = {}) => {
    loading.value = true
    for (const brand of selectedRows.value) {
      const data = { ...updates }
      data.name = brand.name
      await updateBrand(brand.id, data)
    }

    loadBrands()
    loading.value = false
    selectedRows.value = []
  }

  const cachable = [options, filters]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadBrands()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    brandListTable,
    tableColumns,
    filters,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadBrands,
    updateAllBrands,
  }
}
